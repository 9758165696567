import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { BusinessGeneratorPage } from './pages';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<BusinessGeneratorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
