import { useState } from 'react';
import styled from 'styled-components';

import { SPOTIFY_LOADING_TEXT } from '../constants';
import { ReactComponent as Music } from '../assets/svg/music.svg';

interface MainProps {
  children?: JSX.Element | Array<JSX.Element>;
}

const StyledMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  height: ${({ playerVisible }: { playerVisible: boolean }) =>
    playerVisible ? 'calc(100% - 80px);' : '100%'};

  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    min-height: ${({ playerVisible }: { playerVisible: boolean }) =>
      playerVisible ? 'calc(100% - 80px);' : '100%'};
  }

  overflow: hidden;
`;

const SpotifyPlayer = styled.iframe`
  position: fixed;
  right: 0;
  bottom: 0;
`;

const PTAImage = styled.img`
  width: 60px;
  position: fixed;
  z-index: 0;
  bottom: 0;
  right: 0;
  margin-right: 5%;
  margin-bottom: ${({ playerVisible }: { playerVisible: boolean }) =>
    playerVisible ? '120px' : '5%'};

  @media (max-width: 768px) {
    width: 50px;
    margin-right: 5%;
    margin-bottom: ${({ playerVisible }: { playerVisible: boolean }) =>
      playerVisible ? '110px' : '10%'};
  }
`;

const RatingImage = styled.img`
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  margin-left: 5%;
  margin-bottom: ${({ playerVisible }: { playerVisible: boolean }) =>
    playerVisible ? '100px' : '2%'};

  width: 130px;

  @media (max-width: 768px) {
    font-size: 14px;
    width: 50px;
    margin-left: 6%;
    margin-bottom: ${({ playerVisible }: { playerVisible: boolean }) =>
      playerVisible ? '100px' : '8%'};
  }
`;

const StyledMusic = styled(Music)`
  padding: 10px;
  stroke: white;
`;

const Tab = styled.button`
  margin-bottom: ${({ playerVisible }: { playerVisible: boolean }) => playerVisible && '80px;'};
  position: fixed;
  right: 0;
  bottom: 0;
  border: none;
  border-top-left-radius: 10px;
  background-color: #2c79cd;
`;

const SpotifyWrapper = styled.div`
  background-color: #2c79cd;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpotifyLoadingSpan = styled.span`
  color: white;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 25px;
  text-shadow: -1px -1px 0 #f36f44, 1px -1px 0 #f36f44, -1px 1px 0 #f36f44, 1px 1px 0 #f36f44;
  text-transform: uppercase;
`;

export const Main = ({ children }: MainProps) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [showSpotifyLoading, setShowSpotifyLoading] = useState(false);

  return (
    <>
      <StyledMain playerVisible={showPlayer}>{children}</StyledMain>
      <RatingImage playerVisible={showPlayer} src="/static/img/rating.png" alt="rating" />
      <PTAImage playerVisible={showPlayer} src="/static/img/pta.png" alt="PTA" />
      <Tab
        playerVisible={showPlayer}
        onClick={() => {
          setShowPlayer(!showPlayer);
          setShowSpotifyLoading(true);
        }}
      >
        <StyledMusic />
      </Tab>
      {showPlayer && (
        <SpotifyWrapper>
          {showSpotifyLoading && <SpotifyLoadingSpan>{SPOTIFY_LOADING_TEXT}</SpotifyLoadingSpan>}
          <SpotifyPlayer
            loading="eager"
            src="https://open.spotify.com/embed/album/2hZarv2b2vI2j0QRrOv4bt?utm_source=generator"
            width="100%"
            height="80"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            onLoad={() => {
              setShowSpotifyLoading(false);
            }}
          />
        </SpotifyWrapper>
      )}
    </>
  );
};
