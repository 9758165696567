import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { INTRO_DATE, INTRO_PROMPT, INTRO_BTN_TEXT } from '../constants';
import { Main, Container, Button } from '../components';

const StyledContainer = styled(Container)`
  position: absolute;
  z-index: 1;
  align-items: start !important;

  @media (max-width: 768px) {
    margin: 0;
    height: 95vh;
  }
`;

const NoStyleLink = styled(Link)`
  text-decoration: none;
`;

const IntroText = styled.p`
  font-family: 'Kumbh Sans', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  color: #332424;
  font-size: 26px;
  font-weight: 600;
  margin: 0;
  text-shadow: -1px -1px 0 #fad88f, 1px -1px 0 #fad88f, -1px 1px 0 #fad88f, 1px 1px 0 #fad88f;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const IntroPrompt = styled(IntroText)`
  font-weight: 200;
`;

const SplashImage = styled.img`
  z-index: -1;
  bottom: 0;
  right: 0;
  margin-right: -35%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0%;
  }
`;

const ContentContainer = styled.div`
  margin-left: 20%;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-left: 0%;
    margin-top: 10px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    align-self: center;
    flex-direction: column;
    height: 100%;
  }
`;

const TextContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
  }
`;

const LPImage = styled.img`
  width: 400px;

  @media (max-width: 768px) {
    max-width: 70%;
  }
`;

export const HomePage = () => {
  return (
    <Main>
      <SplashImage src="/static/img/splash-image.png" alt="splash" />

      <StyledContainer className="home-container">
        <ContentContainer>
          <LPImage src="/static/img/lp.png" alt="licorice-pizza" width={400} />

          <TextContainer>
            <IntroText>{INTRO_PROMPT}</IntroText>
            <IntroPrompt>{INTRO_DATE}</IntroPrompt>
          </TextContainer>

          <NoStyleLink to="/play">
            <Button>{INTRO_BTN_TEXT}</Button>
          </NoStyleLink>
        </ContentContainer>
      </StyledContainer>
    </Main>
  );
};
