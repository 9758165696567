import styled from 'styled-components';

interface ButtonProps {
  children?: JSX.Element | Array<JSX.Element | string> | string;
  onClick?: () => void;
}

export const ButtonCSS = `
width: 300px;
height: 80px;
background-color: #f36f44;
border: 1px solid #332424;
border-radius: 45px;
text-transform: uppercase;
box-shadow: 0px 10px #332424;
cursor: pointer;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
font-size: 25px;
color: white;
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
font-weight: 600;

:hover {
  background-color: #da643e;
}

opacity: 1;
animation-name: fadeInOpacity;
animation-iteration-count: 1;
animation-timing-function: ease-in;
animation-duration: 1s;

@media (max-width: 768px) {
  width: 200px;
  height: 50px;
  font-size: 20px;
}
`;

const StyledButton = styled.button`
  ${ButtonCSS}
`;

export const Button = ({ children, onClick }: ButtonProps) => {
  return <StyledButton onClick={onClick}>{children}</StyledButton>;
};
