import styled from 'styled-components';

interface ContainerProps {
  children?: JSX.Element | Array<JSX.Element>;
  className: string;
}

const StyledContainer = styled.div`
  width: 95%;
  height: 95%;

  border: 10px solid #f36f44;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 93%;
    margin: 10px;
    height: 100%;
  }
`;

export const Container = ({ children, className }: ContainerProps) => {
  return <StyledContainer className={className}>{children}</StyledContainer>;
};
