import { useState } from 'react';
import styled from 'styled-components';
import { TwitterShareButton } from 'react-share';
import { Textfit } from 'react-textfit';
import { random } from 'lodash';

import { generateName } from '../utils/name';
import { GENERATE_NAME, PROD_URL, TRY_AGAIN, TWEET_CTA, TWEET_HASHTAGS } from '../constants';
import { ReactComponent as Twitter } from '../assets/svg/twitter.svg';
import { Container, Button, ButtonCSS, Main } from '../components';

const Header = styled.div`
  margin-top: 65px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const P = styled.p`
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 20px;
  text-align: center;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 0px;
  }

  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;

const BusinessHeader = styled.h2`
  font-family: 'Kumbh Sans', sans-serif;
  color: #292929;
  font-size: 80px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
  height: 25%;

  @media (max-width: 768px) {
    font-size: 40px;
    font-weight: 600;
  }

  opacity: 1;
  animation-name: nameFade;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;

  @keyframes nameFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  height: 100%;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    width: 75%;
    margin-bottom: 35px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Space = styled.div`
  width: 20px;

  @media (max-width: 768px) {
    height: 20px;
  }
`;

const StyledLogo = styled.img`
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  width: 200px;

  @media (max-width: 768px) {
    width: 150px;
  }
`;

const Sticker = styled.img`
  position: fixed;
  width: 400px;
  right: 0;
  top: 0;
  margin-right: 25vw;
  margin-top: 33vh;
  transform: rotate(20deg);
  z-index: 2;

  @media (max-width: 768px) {
    width: 200px;
    margin-right: 10vw;
    margin-top: 30vh;
  }

  opacity: 0;
  animation-name: stickerFade;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;

  @keyframes stickerFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const StyledTwitterShareButton = styled(TwitterShareButton)`
  ${ButtonCSS}
`;

const TextInput = styled.input`
  border: none;
  border-bottom: 3px solid black;
  width: 100%;
  background: transparent;
  text-align: center;
  font-size: 25px;
`;

const Spinner = styled.img`
  width: 200px;
  margin-top: 100px;
  opacity: 1;

  @media (max-width: 768px) {
    width: 100px;
  }

  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;   

  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
`;

export const BusinessGeneratorPage = () => {
  const [businessName, setBusinessName] = useState<string>();
  const [stickerId, setStickerId] = useState<number>();
  const [word, setWord] = useState<string>();
  const [showSpinner, setShowSpinner] = useState(false);

  const handleGenerateName = async () => {
    const name = generateName(word);
    setShowSpinner(true);

    setTimeout(() => {
      setShowSpinner(false);
      setBusinessName(name);
      setStickerId(random(1, 5, false));
    }, 2000);
  };

  return (
    <Main>
      <Container className="bgp-container">
        <Header>
          <StyledLogo src="/static/img/logo.png" alt="logo" />
        </Header>

        {showSpinner ? <Spinner src="static/img/record.png" /> : <></>}

        {!businessName && !showSpinner ? (
          <Content>
            <P>
              Do you have an idea for a business and just need a name for it? Press the button and
              randomly generate the business name of your dreams.
            </P>

            <TextInput
              placeholder="ENTER A WORD"
              value={word}
              onChange={(e) => {
                setWord(e.currentTarget.value);
              }}
            />
            <Button onClick={handleGenerateName}>{GENERATE_NAME}</Button>
          </Content>
        ) : (
          <></>
        )}

        {businessName && !showSpinner ? (
          <Content>
            {stickerId && (
              <Sticker
                src={`static/img/sticker-${stickerId}.png`}
                alt="layover-sticker"
                key={`${businessName}_${stickerId}`} // new key is needed to re-run animations
              />
            )}

            <BusinessHeader>
              <Textfit mode="single" forceSingleModeWidth={true}>
                {businessName}
                <sup>®</sup>
              </Textfit>
            </BusinessHeader>

            <ButtonGroup>
              <StyledTwitterShareButton
                url={PROD_URL}
                title={`I've got the name for my new business! Say hello to ${businessName}.`}
                hashtags={TWEET_HASHTAGS}
                resetButtonStyle={false}
              >
                <Twitter />
                <Space />
                {TWEET_CTA}
              </StyledTwitterShareButton>

              <Space />

              <Button onClick={handleGenerateName}>{TRY_AGAIN}</Button>
            </ButtonGroup>
          </Content>
        ) : (
          <></>
        )}
      </Container>
    </Main>
  );
};
