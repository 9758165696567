const prefixes = [
  'sunshine',
  'groovy',
  'golden',
  'silver',
  'strawberry',
  "Gary's",
  "Valentine's",
  'funky',
  'freaky',
  'foxy',
  'dream',
  'west coast',
  'love',
  'flower',
  "big daddy's",
  "big mama's",
  'rainbow',
  'los angeles',
  'palm springs',
  'far out'
];
const businessTypes = [
  'laundromat',
  'cinema',
  'bowling',
  'diner',
  'garage',
  'arcade',
  'club',
  'disco',
  'gym',
  'groceries',
  'souvenirs',
  'shack',
  'barber shop',
  'vintage',
  'hotel',
  'records',
  'cafe',
  'tattoos',
  'roller rink',
  'salon',
  'hair and nails',
  'spa'
];

const getRandomElementInArray = (arr: Array<string>): string =>
  arr[Math.floor(Math.random() * arr.length)];

/**
 * If user supplies word, use that as prefix instead of random prefix
 */
export const generateName = (userWord?: string): string =>
  `${userWord || getRandomElementInArray(prefixes)} ${getRandomElementInArray(businessTypes)}`;
